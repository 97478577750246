.gallery-main {
  padding: 20px 60px;
  transition: 300ms;
}

.gallery-main:has(.focused-image-container) > :not(.focused-image-container) {
  opacity: 0.2;
}

.gallery-main h1 {
  text-align: center;
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
}

.gallery-main section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.gallery-main section img {
  height: 300px;
}

.focused-image-container {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 12;
}

.focused-image-container img {
  max-height: 90vh;
  max-width: 90vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left-arrow,
.right-arrow {
  all: unset;
  cursor: pointer;
  position: fixed;
  top: 0;
  width: 30%;
  height: 100vh;
}

.right-arrow {
  right: 0;
}

.left-arrow {
  left: 0;
}

.left-arrow i {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  font-size: 80px;
}

.right-arrow i {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translate(+50%, -50%);
  font-size: 80px;
}

.close-btn {
  all: unset;
  cursor: pointer;
  position: fixed;
  left: 50%;
  bottom: 25px;
  transform: translate(-50%, -25px);
  background-color: white;
  clip-path: circle();
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 790px) {
  .gallery-main h1 {
    font-size: 40px;
  }

  .left-arrow i,
  .right-arrow i {
    font-size: 60px;
  }
}

@media screen and (max-width: 540px) {
  .close-btn {
    bottom: 30px;
    transform: translate(-50%, -30px);
  }

  .left-arrow i,
  .right-arrow i {
    font-size: 40px;
  }

  .gallery-main {
    padding: 10px;
  }
}

@media screen and (max-width: 400px) {
  .close-btn {
    bottom: 50px;
    transform: translate(-50%, -50px);
  }

  .left-arrow i,
  .right-arrow i {
    font-size: 40px;
  }
}
