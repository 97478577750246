.services-main {
  position: relative;
}

.services-container {
  width: 70%;
  padding: 20px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.contacts-container {
  position: fixed;
  /* top: 140px; */
  top: 0;
  padding-top: 280px;
  right: 0;
  width: 30%;
  background-color: rgb(179, 173, 153);
  height: 100vh;
  box-shadow: 10px 0 20px 10px rgba(0, 0, 0);
}

.contacts-container h3 {
  text-align: center;
  font-size: 30px;
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  margin-bottom: 30px;
}

.contacts-container p {
  text-align: center;
  font-family: "korolev", sans-serif;
  font-weight: 300;
  font-style: normal;
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
}

@media screen and (max-width: 1230px) {
  .contacts-container {
    position: static;
    width: 100%;
    height: auto;
    padding: 50px 0;
    box-shadow: unset;
  }

  .services-container {
    width: 100%;
  }
}

@media screen and (max-width: 660px) {
  .services-container {
    padding: 30px;
  }
}
