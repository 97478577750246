.image {
  cursor: zoom-in;
  object-fit: cover;
}

.image-container {
  position: relative;
  text-align: center;
}

.image-container.vertical {
  width: 200px;
  aspect-ratio: 3/4;
}

.image-container.horizontal {
  width: 400px;
  aspect-ratio: 4/3;
}

.image-container.vertical .image {
  width: 100%;
  aspect-ratio: 3/4;
}

.image-container.horizontal .image {
  width: 100%;
  aspect-ratio: 4/3;
}

.delete-btn {
  all: unset;
  cursor: pointer;
  background-color: red;
  color: whitesmoke;
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 5px;
  transition: 300ms;
}

.delete-btn:hover {
  background-color: lightgrey;
  color: black;
}

.image-container.loading .loader {
  position: absolute;
  background-color: lightgrey;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  animation: loading 1300ms infinite;
}

@keyframes loading {
  0% {
    background-color: lightgray;
  }
  50% {
    background-color: whitesmoke;
  }
  100% {
    background-color: lightgray;
  }
}

@media screen and (max-width: 700px) {
  .image-container.horizontal {
    width: 100%;
  }

  .image-container.vertical {
    width: 100%;
  }

  .image-container.vertical .image,
  .image-container.horizontal .image {
    height: 100%;
  }
}
