:root {
  --hero-image-height: 600px;
}

.home-main {
  margin-top: var(--hero-image-height);
}

/* Home.css */

.hero-container {
  position: relative;
  width: 100%;
  height: var(--hero-image-height);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-container h1 {
  position: absolute;
  z-index: 9;
  top: 90%;
  transform: translateY(-90%);
  width: 100%;
  font-family: "subway-berlin-sc", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 60px;
  color: white;
  letter-spacing: 3px;
  text-align: center;
}

.hero-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  animation: fade 24s infinite;
}

.hero-image:nth-child(1) {
  animation-delay: 0s;
}
.hero-image:nth-child(2) {
  animation-delay: 4s;
}
.hero-image:nth-child(3) {
  animation-delay: 8s;
}
.hero-image:nth-child(4) {
  animation-delay: 12s;
}
.hero-image:nth-child(5) {
  animation-delay: 16s;
}
.hero-image:nth-child(6) {
  animation-delay: 20s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  4% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  24% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.home-main {
  padding: 0 100px;
}

.home-main h1 {
  text-align: center;
  font-size: 80px;
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  border-bottom: 3px double #424242;
  width: 600px;
  margin: auto;
  margin-bottom: 50px;
}

.home-main p {
  font-family: "korolev", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  font-size: 30px;
}

@media screen and (max-width: 900px) {
  .hero-container h1 {
    font-size: 40px;
  }

  .home-main h1 {
    font-size: 60px;
    width: 450px;
  }

  .home-main p {
    font-size: 24px;
  }
}

@media screen and (max-width: 700px) {
  .home-main {
    padding: 0 50px;
  }
}

@media screen and (max-width: 600px) {
  .hero-container h1 {
    font-size: 30px;
  }

  .home-main h1 {
    font-size: 50px;
    width: 300px;
  }

  .home-main p {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .hero-container h1 {
    font-size: 20px;
    top: 95%;
  }

  .home-main h1 {
    font-size: 30px;
    width: 200px;
    margin-bottom: 20px;
  }

  .home-main p {
    font-size: 16px;
  }
}

@media screen and (max-width: 350px) {
  .hero-container h1 {
    font-size: 20px;
    width: 100%;
  }
}
