.icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: auto;
  margin-top: 30px;
}

.icons-container a {
  all: unset;
}

.icons-container i {
  font-size: 30px;
  cursor: pointer;
  transition: 300ms;
}

.icons-container i:hover {
  color: #747474;
}

.contact-form {
  width: 90%;
  margin: auto;
  font-family: "korolev", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.form-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.form-row input,
textarea {
  border: 1px solid #424242;
  padding: 10px;
  background-color: white;
  width: 100%;
}

textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.contact-form select {
  width: 100%;
  padding: 10px;
}

.contact-form button {
  all: unset;
  cursor: pointer;
  background-color: #424242;
  color: whitesmoke;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 6px;
  transition: 300ms;
}

.contact-form button:hover {
  background-color: whitesmoke;
  color: #424242;
}

::placeholder {
  color: black;
}

@media screen and (max-width: 1500px) {
  .contact-form {
    width: 80%;
  }
}
